import { put, takeLatest, call } from 'redux-saga/effects'
import { LOGOUT_REQUEST } from 'store/types/auth/logout'
// import { logoutFailed, logoutSuccess } from 'store/actions/auth/logout'
import { logoutService } from 'store/services/auth/logout'
import { removeCookie, getCookie } from 'helpers/cookies'
import { fetchUserOwnDetailClear } from 'store/actions/users/fetchUserOwnDetail'
// import jobseekersLogin from 'store/reducers/auth/jobseekersLogin'
// import jobseekersSocialLogin from 'store/reducers/auth/jobseekersSocialLogin'

function* logoutReq() {
  const payload = {
    source: 'web',
  }
  try {
    const response = yield call(logoutService, payload)
    if (response.status >= 200 && response.status < 300) {
    }
  } catch (err) {
    // yield put(logoutFailed(err))
  }

  removeCookie('user')
  removeCookie('sessionid')
  removeCookie('im_auth_sid')
  removeCookie('remind_user_complete_resume')
  yield put(fetchUserOwnDetailClear())

  try {
    // import('@yolotechnology/imforbossjob')
    //   .then((im) => im?.IMManager?.logout?.())
    //   .then(() => localStorage?.clear?.())
    if (typeof window !== 'undefined') {
      import('bossjob-remote/dist/clientStorage')
        .then(clientStorage => clientStorage?.postNotification?.('IM_LOGOUT'))
    }

  } catch (e) {
    console.log(e)
  }
  const pathname = window?.location?.pathname
  if (pathname === '/' || pathname.indexOf('get-started') >= 0) {
    location?.reload?.()
  } else {
    window?.location?.replace('/')
  }

}

export default function* logout() {
  yield takeLatest(LOGOUT_REQUEST, logoutReq)
}
