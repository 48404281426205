import configuredAxios from 'helpers/configuredAxios'
import { getLanguageCode } from 'helpers/country'

const utmLogsService = (payload) => {
  const endpointType = 'protected'
  const axios = configuredAxios('recruiters')

  return axios.post(`/log_google_utm`, {
    ...payload
  })
}

export { utmLogsService }
