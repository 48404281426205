import React from 'react'
import type { Locale } from './i18n-config'
import { isEmpty } from 'lodash-es'

import enPageLanguage from './dictionaries/en-US.json'
import enErrorcode from './errorcode/en-US/errorcode.json'

import zhPageLanguage from './dictionaries/zh-CN.json'
import zhErrorcode from './errorcode/zh-CN/errorcode.json'

import idPageLanguage from './dictionaries/id-ID.json'
import idErrorcode from './errorcode/id-ID/errorcode.json'

import jpPageLanguage from './dictionaries/ja-JP.json'
import jpErrorcode from './errorcode/ja-JP/errorcode.json'

import twPageLanguage from './dictionaries/zh-TW.json'
import twErrorcode from './errorcode/zh-TW/errorcode.json'


import trPageLanguage from './dictionaries/tr-TR.json'
import trErrorcode from './errorcode/tr-TR/errorcode.json'

import esPageLanguage from './dictionaries/es-ES.json'
import esErrorcode from './errorcode/es-ES/errorcode.json'

import msMYPageLanguage from './dictionaries/ms-MY.json'
import msMYErrorcode from './errorcode/ms-MY/errorcode.json'

const api_base = 'https://api.bossjob.com'

const getServiceManifest = () => {
  return fetch(`${api_base}/translation/manifest`, {
    headers: {
      'Cache-Control': 'no-cache'
    }
  })
    .then(response => response.json())
    .catch(error => {
      throw new Error('Fetch translation/manifest error:', error)
    })
}

const getServiceLanguage = (version, lang, path) => {
  return fetch(`${api_base}/translation/content/version/${version}/${lang}${path}`)
    .then(response => response.json())
    .catch(error => {
      throw new Error('Fetch /translation/content/version error:', error)
    })
}

// We enumerate all dictionaries here for better linting and typescript support
// We also get the default import for cleaner types
const enLanguage = { errorcode: enErrorcode, ...enPageLanguage }
const zhLanguage = { errorcode: zhErrorcode, ...zhPageLanguage }
const idLanguage = { errorcode: idErrorcode, ...idPageLanguage }
const jpLanguage = { errorcode: jpErrorcode, ...jpPageLanguage }
const twLanguage = { errorcode: twErrorcode, ...twPageLanguage }
const trLanguage = { errorcode: trErrorcode, ...trPageLanguage }
const msMYLanguage = { errorcode: msMYErrorcode, ...msMYPageLanguage }
const esLanguage = { errorcode: esErrorcode, ...esPageLanguage }

export const dictionaries = {
  'en-US': () => enLanguage,
  'en-us': () => enLanguage,
  'zh-CN': () => zhLanguage,
  'zh-cn': () => zhLanguage,
  'id': () => idLanguage,
  'id-ID': () => idLanguage,
  'ja-JP': () => jpLanguage,
  'ja': () => jpLanguage,
  'zh-TW': () => twLanguage,
  'zh-tw': () => twLanguage,
  'tr-TR': () => trLanguage,
  'tr': () => trLanguage,
  "ms-MY": () => msMYLanguage,
  "ms": () => msMYLanguage,
  "es-ES": () => esLanguage,
  "es-es": () => esLanguage

}

// convert crowdin language to locale
const crowdinLocaleMaps = {
  'en': 'en',
  'en-US': 'en',
  'en-us': 'en',
  'zh-CN': 'zh-CN',
  'zh-cn': 'zh-CN',
  'id-ID': "id",
  'id': "id",
  'ja-JP': 'ja',
  'ja': 'ja',
  'zh-TW': 'zh-TW',
  'zh-tw': 'zh-TW',
  'tr-TR': 'tr',
  'tr': "tr",
  "ms-MY": "ms",
  "ms": "ms",
  'es-ES': 'es-ES',
  'es-es': 'es-ES'
}

// const log = process.env.DEV_LOCAL_HOST_ENV === 'true' ? console.log : () => void 0

// get matching language in crowdin
export const getMatchCrowdinLang = locale => {
  if (!locale || locale === 'en') return crowdinLocaleMaps['en']
  return crowdinLocaleMaps[locale] || crowdinLocaleMaps['en']
}

const CROWDIN_LOCAL_STORAGE_KEY = 'crowdin-seeker'
const CROWDIN_TIMESTAMP_KEY = '__crowdin_version__'
const CROWDIN_LANGUAGE_KEY = '__crowdin_language__'

const isServer = typeof window === 'undefined'

// cache dictionary in server
const cacheSeekerDic = Object.create(null)

const getCacheData = (lang: string) => {
  if (isServer) {
    return cacheSeekerDic[lang]
  } else {
    // get localStorage data
    return localStorage.getItem(CROWDIN_LOCAL_STORAGE_KEY)
  }
}

const setCacheData = ({ match, data }: { match: string; data: any }) => {
  if (isServer) {
    cacheSeekerDic[match] = JSON.stringify(data)
  } else {
    localStorage.setItem(CROWDIN_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }
}

// 根据 version 判断是否过期。
const getLocalData = ({ version, lang }: { version: string; lang: string }) => {
  if (!version || !lang) return null

  // get cache data
  let localData: any = getCacheData(lang)

  if (!localData) return null

  // parse
  localData = JSON.parse(localData)

  // checked empty
  if (!localData[CROWDIN_LANGUAGE_KEY] || !localData[CROWDIN_TIMESTAMP_KEY]) return null

  // checked language
  if (localData[CROWDIN_LANGUAGE_KEY] !== lang) {
    return null
  }

  // checked expired timestamp
  const isExpired = localData[CROWDIN_TIMESTAMP_KEY] >= version
  // log('Dictionary isExpired::', isExpired, localData[CROWDIN_TIMESTAMP_KEY], timestamp)

  // return data
  return isExpired ? localData : null
}

export const getDictionarySync = (lang: string) => {
  const match = getMatchCrowdinLang(lang)
  const localData = getCacheData(match)
  if (!localData) {
    return
  }
  try {
    return JSON.parse(localData)
  } catch (e) {
    console.log(e)
  }
}

export const getDictionary = React.cache(async (locale: Locale) => {
  try {
    const file_index_config = {
      job_seeker: 17,
      jobseekerPath: "/jobseeker-web/init_language.json",
      error_code: 6,
      errorPath: "/backend/errorcode.json"
    };

    const { data: baseConfig } = await getServiceManifest()
    const { files, active_version } = baseConfig ?? {}

    const match = getMatchCrowdinLang(locale)
    const matchCacheData = getLocalData({ version: active_version, lang: match })
    if (matchCacheData) {
      return matchCacheData
    }

    const getFilePath = (id: number, keyword: string, fallback: string) => {
      const file = files.find(item => item.id == id)
      return file?.path?.includes(keyword) ? file?.path : fallback
    };

    const [dic, errorcode] = await Promise.all([
      getServiceLanguage(
        active_version,
        match,
        getFilePath(file_index_config.job_seeker, "jobseeker-web", file_index_config.jobseekerPath)
      ),
      getServiceLanguage(
        active_version,
        match,
        getFilePath(file_index_config.error_code, "errorcode", file_index_config.errorPath)
      ),
    ]);


    if (isEmpty(dic) || isEmpty(errorcode)) {
      return dictionaries[locale]?.() || dictionaries['en-us']()
    }

    // merge data
    const data = {
      ...dic,
      errorcode,
      [CROWDIN_TIMESTAMP_KEY]: active_version,
      [CROWDIN_LANGUAGE_KEY]: match,
    }

    // save data to cache
    setCacheData({ match, data })

    return data
  } catch (error) {
    return dictionaries[locale]?.() || dictionaries['en-us']()
  }
})