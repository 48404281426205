const adjustUrlList = [
  // H5
  {
    link: {
      default: 'https://app.adjust.com/1i4nq4pa',
      ph: 'https://bossjob.go.link/?adj_t=1lyjo9jw&adj_engagement_type=fallback_click',
      sg: 'https://bossjob.go.link/?adj_t=1l7vqyfh&adj_engagement_type=fallback_click',
      jp: 'https://bossjob.go.link/?adj_t=1lyyz33j&adj_engagement_type=fallback_click'
    },
    adjustConf: 'https://suite.adjust.com/custom-links/1i4nq4pa/adgroup/link-urls',
    comment: '全局浮动下载横幅'
  },
  {
    link: {
      default: "https://bossjob.go.link/bossjob.ph/home?adj_t=1izto6rx",
      ph: 'https://bossjob.go.link/?adj_t=1ltl13r4&adj_engagement_type=fallback_click',
      sg: 'https://bossjob.go.link/?adj_t=1lo2iuzn&adj_engagement_type=fallback_click',
      jp: 'https://bossjob.go.link/?adj_t=1lj8ra8x&adj_engagement_type=fallback_click'
    },
    adjustConf: 'https://suite.adjust.com/custom-links/1izto6rx/adgroup/link-urls',
    comment: 'APP落地页下载'
  },
  {
    link: {
      default: "https://bossjob.go.link/bossjob.ph/home?adj_t=1iny6pk1",
      ph: 'https://bossjob.go.link/?adj_t=1lgul8po&adj_engagement_type=fallback_click',
      sg: 'https://bossjob.go.link/?adj_t=1lc047hz&adj_engagement_type=fallback_click',
      jp: 'https://bossjob.go.link/?adj_t=1l5b0xwq&adj_engagement_type=fallback_click'
    },
    adjustConf: 'https://suite.adjust.com/custom-links/1iny6pk1/adgroup/link-urls',
    comment: 'APP落地页Footer'
  },

  // Web - 求职端
  {
    link: "https://app.adjust.com/1id1um8d",
    adjustConf: 'https://suite.adjust.com/custom-links/1id1um8d/adgroup/link-urls',
    comment: '主页横幅'
  },
  // download btn
  {
    link: {
      default: 'https://app.adjust.com/1ii3hya6',
      ph: 'https://bossjob.go.link/?adj_t=1lpfgtm7&adj_engagement_type=fallback_click',
      sg: 'https://bossjob.go.link/?adj_t=1ls137d9&adj_engagement_type=fallback_clickss',
      jp: 'https://bossjob.go.link/?adj_t=1leit4gb&adj_engagement_type=fallback_click'
    },
    adjustConf: 'https://suite.adjust.com/custom-links/1ii3hya6/adgroup/link-urls',
    comment: '全局Footer'
  },
  {
    link: {
      default: "https://app.adjust.com/1ik4j1mk",
      ph: 'https://bossjob.go.link/?adj_t=1lg7v7ra&adj_engagement_type=fallback_click',
      sg: 'https://bossjob.go.link/?adj_t=1lydcadz&adj_engagement_type=fallback_click',
      jp: 'https://bossjob.go.link/?adj_t=1lsss0t7&adj_engagement_type=fallback_click'
    },
    adjustConf: 'https://suite.adjust.com/custom-links/1ik4j1mk/adgroup/link-urls',
    comment: 'APP落地页'
  },
  {
    link: {
      default: 'https://app.adjust.com/1isifr89',
      ph: 'https://bossjob.go.link/?adj_t=1lt2ls4c&adj_engagement_type=fallback_click',
      sg: 'https://bossjob.go.link/?adj_t=1lfv75te&adj_engagement_type=fallback_click',
      jp: 'https://bossjob.go.link/?adj_t=1lyns3mt&adj_engagement_type=fallback_click'
    },
    adjustConf: 'https://suite.adjust.com/custom-links/1isifr89/adgroup/link-urls',
    comment: 'APP落地页footer'
  },
  {
    link: 'https://bossjob.go.link/bossjob.ph/job-details?adj_t=1irm40ji&adj_deep_link=bossjob://bossjob.ph/job-details?jobId=',
    adjustConf: 'https://suite.adjust.com/custom-links/1irm40ji/adgroup/link-urls',
    comment: '工作列表'
  },
  {
    link: 'https://bossjob.go.link/bossjob.ph/chat-home?adj_t=1in8ahrl',
    adjustConf: 'https://suite.adjust.com/custom-links/1in8ahrl/adgroup/link-urls',
    comment: 'PC Chat 聊天窗口'
  },
  // new add H5
  {
    link: 'https://bossjob.go.link/?adj_t=1l90o9yn&adj_engagement_type=fallback_click',
    adjustConf: 'https://suite.adjust.com/custom-links/1iy211g7/campaign/link-urls',
    comment: 'H5 Chat聊天窗口'
  }
] as any

export const appStoreLink = 'https://app.adjust.com/1id1um8d'

export const googleStoreLink = 'https://app.adjust.com/1id1um8d'

export default adjustUrlList